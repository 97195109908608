<template>
  <div class="max-w-screen-tv-main-axis max-h-screen-tv-cross-axis mx-auto">
    <Header :titlesVisibility="false" />
    <div class="flex flex-col text-center absolute w-2/3 left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2">
      <img :src="logo" alt="Logo" class="w-80 mx-auto">
      <h1 class="font-heading text-h1 text-redDamask dark:text-redDamask mt-10 mb-10">{{ titleStore.exhibitionTitle.title }}</h1>
      <div class="grid self-center" style="grid-template-columns: auto auto auto;">
        <template v-for="(subtitle, index) in subtitles">
          <a :href="subtitle.link" class="cursor-pointer flex items-center border-b border-redDamask border-opacity-20 mb-3 pb-3 pr-5">
            <h3 class="font-heading text-h3 text-sepiaBlack dark:text-redDamask">{{ index + 1 }}.</h3>
          </a>
          <a :href="subtitle.link" class="cursor-pointer flex items-center border-b border-redDamask border-opacity-20 mb-3 pb-3 pr-6">
            <h3 class="font-heading text-h3 text-sepiaBlack dark:text-halfColonialWhite">{{ subtitle.title }}</h3>
          </a>
          <a :href="subtitle.link" class="cursor-pointer flex items-center border-b border-redDamask border-opacity-20 mb-3 pb-3 pr-3">
            <img src="@/assets/icons/arrowRight.svg" alt="arrow">
          </a>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
  import Header from "@/components/Header.vue";
  import Logo from '@/assets/mjm-logo.svg';
  import { titleStore } from '../../store/store.js';

  export default {
    name: "Home",

    components: {
      Header,
    },

    data() {
      return {
        logo: Logo,
        subtitles: titleStore.exhibitionSubtitles,
        titleStore,
      }
    },
    
    watch: {
      titleStore: {
        handler: function (val) {
          this.subtitles = val.exhibitionSubtitles;
        },
        deep: true
      }
    }
  };
</script>
